import logo_light from '../img/logo.png'
import logo_dark from '../img/logo2.png'
import { IoIosArrowBack } from 'react-icons/io'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import { deliveryOptions } from '../data'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import useDarkMode from '../hooks/useDarkMode'
import 'swiper/css'
import 'swiper/css/pagination'

const Delivery = ({ setSideNav, sideNav, close }) => {
  const [swiper, setSwiper] = useState()
  const navigate = useNavigate()
  useDarkMode()
  const pagination = {
    el: '.delivery-pagination',
    clickable: true,
    renderBullet: (index, className) => {
      return `
        <span class="${className}">
          <span className="text-xs whitespace-nowrap font-semibold">
            ${deliveryOptions[index]?.name}
          </span>
          <Image
            src=${deliveryOptions[index]?.img}
            alt=""
            width="24"
            height="24"
            priority
            className='z-10'
          />
        </span>
      `
    },
  }

  return (
    <>
      <div className='w-full sticky z-[50] inset-0 bg-white max-w-md mx-auto h-16 top-auto shadow-[1px_1px_8px_#d59f2966] py-1 flex items-center justify-between gap-2 dark:bg-gray-700'>
        <div className='col-span-9 grid grid-cols-12 justify-start items-center'>
          <Link
            to='/'
            className='col-span-10 pr-4 text-md font-semibold text-gray-500 dark:text-white overflow-y-hidden flex items-center cursor-pointer gap-2'
          >
            <img
              src={logo_light}
              alt='main logo'
              className=' w-[56px] h-[57px] dark:hidden'
            />
            <img
              src={logo_dark}
              alt='main logo'
              className=' w-[56px] h-[57px] hidden dark:block'
            />
            <h1 className='font-extrabold flex gap-1 flex-col text-md text-primaryGreen-500 dark:text-primaryGreen-200'>
              <span className='inline-block transform translate-y-1 mx-0.5 text-brown-400'>
                مطعم نوى الشام
              </span>
              <span className='font-extrabold text-md text-primaryGreen-500 dark:text-primaryGreen-200 overflow-hidden'>
                {' '}
                Nawa Sham
              </span>
            </h1>
          </Link>
        </div>
        <IoIosArrowBack
          className='w-10 h-10 ml-4 rounded-full text-[#d59f29] hover:bg-main-900/80 hover:text-white dark:text-white transition p-2 cursor-pointer'
          title='رجوع'
          onClick={() => navigate(-1)}
        />
      </div>
      <div className='delivery-pagination'></div>
      <Swiper
        loop={true}
        autoHeight={true}
        pagination={pagination}
        speed={500}
        modules={[Pagination]}
        onSwiper={(swiper) => setSwiper(swiper)}
        allowTouchMove={false}
        onSlideChange={() => {
          const activeCat = document.querySelector(
            '.swiper-pagination-bullet-active'
          )
          document.documentElement.scrollTop = 0
          activeCat?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          })
          close()
        }}
      >
        {deliveryOptions.map((option) => (
          <SwiperSlide key={option.id}>
            <option.component swiper={swiper} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}

export default Delivery
