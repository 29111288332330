import { FaSnapchat, FaInstagram, FaWhatsapp, FaTiktok, FaPhoneAlt } from 'react-icons/fa'
import ReciveFromStore from '../components/ReciveFromStore'
import TableNumber from '../components/TableNumber'
import Transport from '../components/Transport'

export const sideNavData = [
  {
    id: 1,
    text: 'سناب شات',
    icon: FaSnapchat,
    href: 'https://www.snapchat.com/add/nwasham10',
  },
  {
    id: 2,
    text: "تيك توك",
    icon: FaTiktok,
    href: "https://www.tiktok.com/@nwasham.99/",
  },
  {
    id: 3,
    text: 'إنستجرام',
    icon: FaInstagram,
    href: 'https://www.instagram.com/nwaalsham.999/',
  },
  {
    id: 4,
    text: 'واتساب',
    icon: FaWhatsapp,
    href: 'https://api.whatsapp.com/send/?phone=966534408822',
  },
  { id: 5, text: 'اتصل بنا', icon: FaPhoneAlt, href: 'tel:0534408822' },
]
export const data = [
  {
    id: 1,
    title: ' المشويات',
    image: '../../../img/list/mshwe.png',
    selected: true,
    items: [
      {
        id: 1,
        title: 'مشكل مشويات',
        description: 'نفر - نصف كيلو - كيلو',
        image: '../../img/items.png',
        price: 33,
        calories: 0,
        selected: false,
        size: {
          small: 33,
          medium: 65,
          large: 126,
        },
      },
      {
        id: 2,
        title: 'مشكل لحم',
        description: 'نفر - نصف كيلو - كيلو',
        image: '../../img/items.png',
        price: 38,
        calories: 0,
        selected: false,
        size: {
          small: 38,
          medium: 73,
          large: 145,
        },
      },
      {
        id: 3,
        title: 'مشكل دجاج',
        description: 'نفر - نصف كيلو - كيلو',
        image: '../../img/items.png',
        price: 33,
        calories: 0,
        selected: false,
        size: {
          small: 33,
          medium: 65,
          large: 126,
        },
      },
      {
        id: 4,
        title: 'كباب لحم',
        description: 'نفر - نصف كيلو - كيلو',
        image: '../../img/items.png',
        price: 33,
        calories: 0,
        selected: false,
        size: {
          small: 33,
          medium: 65,
          large: 126,
        },
      },
      {
        id: 5,
        title: 'كباب دجاج',
        description: 'نفر - نصف كيلو - كيلو',
        image: '../../img/items.png',
        price: 30,
        calories: 0,
        selected: false,
        size: {
          small: 30,
          medium: 58,
          large: 114,
        },
      },
      {
        id: 6,
        title: 'اوصال لحم',
        description: 'نفر - نصف كيلو - كيلو',
        image: '../../img/items.png',
        price: 40,
        calories: 0,
        selected: false,
        size: {
          small: 40,
          medium: 79,
          large: 155,
        },
      },
      {
        id: 7,
        title: 'شيش طاووق',
        description: 'نفر - نصف كيلو - كيلو',
        image: '../../img/items.png',
        price: 33,
        calories: 0,
        selected: false,
        size: {
          small: 33,
          medium: 65,
          large: 126,
        },
      },
      {
        id: 8,
        title: 'نفر ريش',
        description: '',
        image: '../../img/items.png',
        price: 40,
        calories: 0,
        selected: false,
      },
      {
        id: 9,
        title: 'نفر ستيك',
        description: '',
        image: '../../img/items.png',
        price: 27,
        calories: 0,
        selected: false,
      },
      {
        id: 10,
        title: 'نفر مشكل كباب',
        description: 'نفر - نصف كيلو - كيلو',
        image: '../../img/items.png',
        price: 33,
        calories: 0,
        selected: false,
        size: {
          small: 33,
          medium: 65,
          large: 120,
        },
      },
      {
        id: 11,
        title: 'نفر مشكل اوصال',
        description: 'نفر - نصف كيلو - كيلو',
        image: '../../img/items.png',
        price: 46,
        calories: 0,
        selected: false,
        size: {
          small: 46,
          medium: 86,
          large: 161,
        },
      },
      {
        id: 12,
        title: 'كبه على السيخ',
        description: '',
        image: '../../img/items.png',
        price: 27,
        calories: 0,
        selected: false,
      },
      {
        id: 13,
        title: 'صحن مشويات نوى الشام',
        description: 'ستيك - ريش - كبة',
        image: '../../img/items.png',
        price: 195,
        calories: 0,
        selected: false,
      },
    ],
  },
  {
    id: 2,
    title: ' الفطائر',
    image: '../../../img/list/ftair.png',
    selected: true,
    items: [
      {
        id: 14,
        title: 'صحن فطاير شامية',
        description: '',
        image: '../../img/items.png',
        price: 46,
        calories: 0,
        selected: false,
        size: {
          small: 46,
          medium: 70,
          large: 0,
        },
      },
      {
        id: 15,
        title: 'صحن فطاير شامية مع بيتزا',
        description: '',
        image: '../../img/items.png',
        price: 80,
        calories: 0,
        selected: false,
      },
      {
        id: 16,
        title: 'عش البلبل',
        description: '',
        image: '../../img/items.png',
        price: 14,
        calories: 0,
        selected: false,
        size: {
          small: 14,
          medium: 18,
          large: 23,
        },
      },
      {
        id: 17,
        title: 'لحم جبن',
        description: '',
        image: '../../img/items.png',
        price: 9,
        calories: 0,
        selected: false,
      },
      {
        id: 18,
        title: 'لحم ساده',
        description: '',
        image: '../../img/items.png',
        price: 8,
        calories: 0,
        selected: false,
      },
      {
        id: 19,
        title: 'جبن عكاوي',
        description: '',
        image: '../../img/items.png',
        price: 9,
        calories: 0,
        selected: false,
      },
      {
        id: 20,
        title: 'لبنة ساده',
        description: '',
        image: '../../img/items.png',
        price: 7,
        calories: 0,
        selected: false,
      },
      {
        id: 21,
        title: 'لبنة زعتر',
        description: '',
        image: '../../img/items.png',
        price: 8,
        calories: 0,
        selected: false,
      },
      {
        id: 22,
        title: 'لبنة جبنة',
        description: '',
        image: '../../img/items.png',
        price: 8,
        calories: 0,
        selected: false,
      },
      {
        id: 23,
        title: 'لبنة زيتون',
        description: '',
        image: '../../img/items.png',
        price: 8,
        calories: 0,
        selected: false,
      },
      {
        id: 24,
        title: 'محمره ساده',
        description: '',
        image: '../../img/items.png',
        price: 7,
        calories: 0,
        selected: false,
      },
      {
        id: 25,
        title: 'محمره جبن',
        description: '',
        image: '../../img/items.png',
        price: 8,
        calories: 0,
        selected: false,
      },
      {
        id: 26,
        title: 'محمره دجاج',
        description: '',
        image: '../../img/items.png',
        price: 9,
        calories: 0,
        selected: false,
      },
      {
        id: 27,
        title: 'فطيره دجاج',
        description: '',
        image: '../../img/items.png',
        price: 9,
        calories: 0,
        selected: false,
      },
      {
        id: 28,
        title: 'جبن سائل',
        description: '',
        image: '../../img/items.png',
        price: 8,
        calories: 0,
        selected: false,
      },
      {
        id: 29,
        title: 'جبن سائل زعتر',
        description: '',
        image: '../../img/items.png',
        price: 9,
        calories: 0,
        selected: false,
      },
      {
        id: 30,
        title: 'جبن سائل عسل',
        description: '',
        image: '../../img/items.png',
        price: 9,
        calories: 0,
        selected: false,
      },
      {
        id: 31,
        title: 'سبانخ ساده',
        description: '',
        image: '../../img/items.png',
        price: 7,
        calories: 0,
        selected: false,
      },
      {
        id: 32,
        title: 'سبانخ جبن',
        description: '',
        image: '../../img/items.png',
        price: 8,
        calories: 0,
        selected: false,
      },
      {
        id: 33,
        title: 'فطيره مرتديلا ساده',
        description: '',
        image: '../../img/items.png',
        price: 8,
        calories: 0,
        selected: false,
      },
      {
        id: 34,
        title: 'فطيره مرتديلا جين',
        description: '',
        image: '../../img/items.png',
        price: 9,
        calories: 0,
        selected: false,
      },
      {
        id: 35,
        title: 'فلافل لبنه',
        description: '',
        image: '../../img/items.png',
        price: 8,
        calories: 0,
        selected: false,
      },
      {
        id: 36,
        title: 'فلافل لبنه جبن',
        description: '',
        image: '../../img/items.png',
        price: 9,
        calories: 0,
        selected: false,
      },
      {
        id: 37,
        title: 'زعتر',
        description: '',
        image: '../../img/items.png',
        price: 6,
        calories: 0,
        selected: false,
      },
      {
        id: 38,
        title: 'زعتر جبن',
        description: '',
        image: '../../img/items.png',
        price: 7,
        calories: 0,
        selected: false,
      },
      {
        id: 39,
        title: 'جبن بيض',
        description: '',
        image: '../../img/items.png',
        price: 7,
        calories: 0,
        selected: false,
      },
      {
        id: 40,
        title: 'فطيره بنت الصحراء',
        description: '',
        image: '../../img/items.png',
        price: 18,
        calories: 0,
        selected: false,
        size: {
          small: 18,
          medium: 23,
          large: 29,
        },
      },
    ],
  },
  {
    id: 3,
    title: 'البيتزا',
    image: '../../../img/list/pizza.png',
    selected: false,
    items: [
      {
        id: 41,
        title: 'بيتزا مارغريتا',
        description: '',
        image: '../../img/items.png',
        price: 14,
        calories: 0,
        selected: false,
        size: {
          small: 14,
          medium: 19,
          large: 25,
        },
      },
      {
        id: 42,
        title: 'بيتزا خضار',
        description: '',
        image: '../../img/items.png',
        price: 14,
        calories: 0,
        selected: false,
        size: {
          small: 14,
          medium: 19,
          large: 25,
        },
      },
      {
        id: 43,
        title: 'بيتزا لحم',
        description: '',
        image: '../../img/items.png',
        price: 16,
        calories: 0,
        selected: false,
        size: {
          small: 16,
          medium: 22,
          large: 32,
        },
      },
      {
        id: 44,
        title: 'بيتزا دجاج',
        description: '',
        image: '../../img/items.png',
        price: 16,
        calories: 0,
        selected: false,
        size: {
          small: 16,
          medium: 22,
          large: 31,
        },
      },
      {
        id: 45,
        title: ' بيتزا نقانق',
        description: '',
        image: '../../img/items.png',
        price: 16,
        calories: 0,
        selected: false,
        size: {
          small: 16,
          medium: 22,
          large: 31,
        },
      },
      {
        id: 46,
        title: 'بيتزا مرتديلا ',
        description: '',
        image: '../../img/items.png',
        price: 16,
        calories: 0,
        selected: false,
        size: {
          small: 16,
          medium: 22,
          large: 31,
        },
      },
      {
        id: 47,
        title: 'بيتزا الفصول الأربعه',
        description: '',
        image: '../../img/items.png',
        price: 16,
        calories: 0,
        selected: false,
        size: {
          small: 16,
          medium: 22,
          large: 31,
        },
      },
      {
        id: 48,
        title: 'بيتزا أربع أجبان',
        description: '',
        image: '../../img/items.png',
        price: 16,
        calories: 0,
        selected: false,
        size: {
          small: 16,
          medium: 22,
          large: 31,
        },
      },
      {
        id: 49,
        title: 'بيتزا محشية الأطراف',
        description: '',
        image: '../../img/items.png',
        price: 16,
        calories: 0,
        selected: false,
        size: {
          small: 16,
          medium: 22,
          large: 31,
        },
      },
    ],
  },
  {
    id: 4,
    title: 'الساندوتشات',
    image: '../../../img/list/sandwich.png',
    selected: false,
    items: [
      {
        id: 50,
        title: 'كباب دجاج',
        description: '',
        image: '../../img/items.png',
        price: 9,
        calories: 0,
        selected: false,
      },
      {
        id: 51,
        title: 'كباب لحم',
        description: '',
        image: '../../img/items.png',
        price: 9,
        calories: 0,
        selected: false,
      },
      {
        id: 52,
        title: 'أوصال لحم',
        description: '',
        image: '../../img/items.png',
        price: 10,
        calories: 0,
        selected: false,
      },
      {
        id: 53,
        title: 'شيش طاووق',
        description: '',
        image: '../../img/items.png',
        price: 10,
        calories: 0,
        selected: false,
      },
      {
        id: 54,
        title: 'برجر حاشي',
        description: 'وجبه',
        image: '../../img/items.png',
        price: 23,
        calories: 0,
        selected: false,
      },
      {
        id: 55,
        title: 'برجر حاشي',
        description: 'ساندوتش',
        image: '../../img/items.png',
        price: 19,
        calories: 0,
        selected: false,
      },
      {
        id: 56,
        title: 'برجر دجاج',
        description: 'وجبه',
        image: '../../img/items.png',
        price: 18,
        calories: 0,
        selected: false,
      },
      {
        id: 57,
        title: 'برجر دجاج',
        description: 'ساندوتش',
        image: '../../img/items.png',
        price: 13,
        calories: 0,
        selected: false,
      },
      {
        id: 58,
        title: 'برجر غنم',
        description: 'وجبه',
        image: '../../img/items.png',
        price: 22,
        calories: 0,
        selected: false,
      },
      {
        id: 59,
        title: 'برجر غنم',
        description: 'ساندوتش',
        image: '../../img/items.png',
        price: 17,
        calories: 0,
        selected: false,
      },
      {
        id: 60,
        title: 'برجر فرن',
        description: 'وجبه',
        image: '../../img/items.png',
        price: 30,
        calories: 0,
        selected: false,
      },
      {
        id: 61,
        title: 'برجر فرن',
        description: 'ساندوتش',
        image: '../../img/items.png',
        price: 20,
        calories: 0,
        selected: false,
      },
      {
        id: 62,
        title: 'شيش فرن',
        description: 'وجبه',
        image: '../../img/items.png',
        price: 18,
        calories: 0,
        selected: false,
      },
      {
        id: 63,
        title: 'شيش فرن',
        description: 'ساندوتش',
        image: '../../img/items.png',
        price: 13,
        calories: 0,
        selected: false,
      },
      {
        id: 64,
        title: 'كباب فرن',
        description: 'وجبه',
        image: '../../img/items.png',
        price: 18,
        calories: 0,
        selected: false,
      },
      {
        id: 65,
        title: 'كباب فرن',
        description: 'ساندوتش',
        image: '../../img/items.png',
        price: 13,
        calories: 0,
        selected: false,
      },
      {
        id: 66,
        title: 'أيطالي لحم',
        description: 'وجبه',
        image: '../../img/items.png',
        price: 20,
        calories: 0,
        selected: false,
      },
      {
        id: 67,
        title: 'أيطالي لحم',
        description: 'ساندوتش',
        image: '../../img/items.png',
        price: 15,
        calories: 0,
        selected: false,
      },
      {
        id: 68,
        title: 'أيطالي دجاج',
        description: 'وجبه',
        image: '../../img/items.png',
        price: 18,
        calories: 0,
        selected: false,
      },
      {
        id: 69,
        title: 'أيطالي دجاج',
        description: 'ساندوتش',
        image: '../../img/items.png',
        price: 13,
        calories: 0,
        selected: false,
      },
      {
        id: 70,
        title: 'شكن ديناميت',
        description: 'وجبه',
        image: '../../img/items.png',
        price: 23,
        calories: 0,
        selected: false,
      },
      {
        id: 71,
        title: 'شريم ديناميت',
        description: 'وجبه',
        image: '../../img/items.png',
        price: 25,
        calories: 0,
        selected: false,
      },
      {
        id: 95,
        title: 'شيش إيطالي',
        description: 'ساندوتش',
        image: '../../img/items.png',
        price: 17,
        calories: 0,
        selected: false,
      },
      {
        id: 96,
        title: 'شيش إيطالي',
        description: 'وجبه',
        image: '../../img/items.png',
        price: 22,
        calories: 0,
        selected: false,
      },
    ],
  },
  {
    id: 5,
    title: 'الطواجن',
    image: '../../../img/list/dish.png',
    selected: false,
    items: [
      {
        id: 72,
        title: 'طاجن شرحات الحم',
        description: '',
        image: '../../img/items.png',
        price: 27,
        calories: '',
        selected: false,
        size: {
          small: 27,
          large: 38,
        },
      },
      {
        id: 73,
        title: 'طاجن شرحات دجاج',
        description: '',
        image: '../../img/items.png',
        price: 27,
        calories: '',
        selected: false,
        size: {
          small: 27,
          large: 38,
        },
      },
      {
        id: 74,
        title: 'طاجن كفته لحم',
        description: '',
        image: '../../img/items.png',
        price: 39,
        calories: '',
        selected: false,
      },
    ],
  },
  {
    id: 6,
    title: 'البروستد',
    image: '../../../img/list/brstd.png',
    selected: false,
    items: [
      {
        id: 75,
        title: 'برستد حراق',
        description: '',
        image: '../../img/items.png',
        price: 19,
        calories: 0,
        selected: false,
      },
      {
        id: 76,
        title: 'برستد عادي',
        description: '',
        image: '../../img/items.png',
        price: 19,
        calories: 0,
        selected: false,
      },
    ],
  },
  {
    id: 7,
    title: 'المقبلات',
    image: '../../../img/list/fattah.png',
    selected: false,
    items: [
      {
        id: 77,
        title: 'مقبلات مشكله',
        description: '',
        image: '../../img/items.png',
        price: 14,
        calories: '',
        selected: false,
        size: {
          small: 14,
          medium: 19,
          large: 25,
        },
      },
      {
        id: 78,
        title: 'حمص',
        description: '',
        image: '../../img/items.png',
        price: 9,
        calories: 0,
        selected: false,
      },
      {
        id: 79,
        title: 'متبل',
        description: '',
        image: '../../img/items.png',
        price: 9,
        calories: 0,
        selected: false,
      },
      {
        id: 80,
        title: 'بابا غنوج',
        description: '',
        image: '../../img/items.png',
        price: 9,
        calories: 0,
        selected: false,
      },
      {
        id: 81,
        title: 'تبوله',
        description: '',
        image: '../../img/items.png',
        price: 10,
        calories: 0,
        selected: false,
      },
      {
        id: 82,
        title: 'فتوش',
        description: '',
        image: '../../img/items.png',
        price: 10,
        calories: 0,
        selected: false,
      },
      {
        id: 83,
        title: 'سلطه زيتون',
        description: '',
        image: '../../img/items.png',
        price: 10,
        calories: 0,
        selected: false,
      },
      {
        id: 84,
        title: 'سلطه خضراء',
        description: '',
        image: '../../img/items.png',
        price: 10,
        calories: 0,
        selected: false,
      },
      {
        id: 85,
        title: 'ورق عنب',
        description: '6قطع-12قطع-20قطع',
        image: '../../img/items.png',
        price: 10,
        calories: '',
        selected: false,
        size: {
          small: 10,
          medium: 20,
          large: 30,
        },
      },
    ],
  },
  {
    id: 8,
    title: 'العصائر الطازجة',
    image: '../../../img/list/juc.png',
    selected: false,
    items: [
      {
        id: 86,
        title: 'برتقال كبس',
        description: '',
        image: '../../img/food/bort8al.png',
        price: 10,
        calories: 0,
        selected: false,
      },
      {
        id: 87,
        title: 'فراوله',
        description: '',
        image: '../../img/food/fraolh.png',
        price: 10,
        calories: 0,
        selected: false,
      },
      {
        id: 88,
        title: 'كوكتيل',
        description: '',
        image: '../../img/food/koktil.png',
        price: 10,
        calories: 0,
        selected: false,
      },
      {
        id: 89,
        title: 'ليمون نعناع',
        description: '',
        image: '../../img/food/limon3.png',
        price: 10,
        calories: 0,
        selected: false,
      },
      {
        id: 90,
        title: 'مانجو',
        description: '',
        image: '../../img/food/mango.png',
        price: 10,
        calories: 0,
        selected: false,
      },
    ],
  },
  {
    id: 8,
    title: 'مشروبات غازية',
    image: '../../../img/list/ghas.png',
    selected: false,
    items: [
      {
        id: 91,
        title: 'سفن أب',
        description: 'صغير - وسط - عائلي',
        image: '../../img/food/sfn.jpeg',
        price: 3,
        calories: 0,
        selected: false,
        size: {
          small: 3,
          medium: 8,
          large: 12,
        },
      },
      {
        id: 92,
        title: 'ببسي',
        description: 'صغير - وسط - عائلي',
        image: '../../img/food/pebsi.jpg',
        price: 3,
        calories: 0,
        selected: false,
        size: {
          small: 3,
          medium: 8,
          large: 12,
        },
      },
      {
        id: 93,
        title: 'كوكاكولا',
        description: 'صغير - وسط - عائلي',
        image: '../../img/food/cocacola.png',
        price: 3,
        calories: 0,
        selected: false,
        size: {
          small: 3,
          medium: 8,
          large: 12,
        },
      },
      {
        id: 94,
        title: 'مياة معدنية',
        description: '',
        image: '../../img/food/ber.png',
        price: 1,
        calories: 0,
        selected: false,
      },
    ],
  },
]
export const deliveryOptions = [
  {
    id: 1,
    component: Transport,
    name: 'توصيل',
    img: '../../img/delivery/delivery.png',
  },
  {
    id: 2,
    component: ReciveFromStore,
    name: 'إستلام',
    img: '../../img/delivery/pickup.png',
  },
  {
    id: 3,
    component: TableNumber,
    name: 'إختيار الطاولة',
    img: '../../img/delivery/number.png',
  },
]
