import { useState } from 'react'
import Map from './Map'
import { AiOutlineClockCircle } from 'react-icons/ai'

const ReciveFromStore = ({ swiper }) => {
  const [resLatLng, setResLatLng] = useState({ lat: 0, lng: 0 })
  const [value, setValue] = useState(0)

  const calcCrow = (lat1, lon1, lat2, lon2) => {
    var R = 6371 // km
    var dLat = toRad(lat2 - lat1)
    var dLon = toRad(lon2 - lon1)
    var latitude1 = toRad(lat1)
    var latitude2 = toRad(lat2)

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) *
        Math.sin(dLon / 2) *
        Math.cos(latitude1) *
        Math.cos(latitude2)
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    var d = R * c
    return d
  }
  const toRad = (Value) => {
    return (Value * Math.PI) / 180
  }

  const handleSubmit = (e) => {
    e.target.reset()
    if (resLatLng.lat === 0) {
      alert('قم بالسماح بتحديد الموقع لتحديد موقعك آلياً')
    } else {
      const distance = calcCrow(24.43265, 39.6536, resLatLng.lat, resLatLng.lng)
      if (distance < 1000) {
        alert('عفواً، لا نستطيع التوصيل لأي موقع أبعد من 1000 كيلومترات')
      } else {
        swiper.slideTo(2)
      }
    }
  }

  const handleResClick = (coords, index) => {
    setValue(index)
    setResLatLng(coords)
  }

  const restaurants = [
    {
      id: 1,
      name: 'فرع السويد',
      distance: 3235,
      addrress:
        '7053 عائشة بنت أبي بكر، السويدي، الرياض 12795 2187،، السويدي، الرياض 12795، السعودية',
      open: true,
      open24h: false,
      searchQuery: 'عائشة بنت أبي بكر, السعودية',
      coords: { lat: 23.78553, lng: 44.80205 },
    },
    {
      id: 2,
      name: 'Al-Malqa Branch فرع الملقا',
      distance: 1390,
      addrress: '3319 Anas Ibn Malik Rd, Al Malqa, Riyadh 13521, Saudi Arabia',
      open: true,
      open24h: true,
      searchQuery: 'Al Malqa, Riyadh, Saudi Arabia',
      coords: { lat: 23.88285, lng: 45.3834 },
    },
    {
      id: 3,
      name: 'Tabuk Branch فرع تبوك',
      distance: 444287,
      addrress: '',
      open: false,
      open24h: false,
      searchQuery: 'تبوك, السعودية',
      coords: { lat: 28.42084, lng: 36.61893 },
    },
  ]
  return (
    <div className='relative h-[calc(100vh-136px)] w-full overflow-hidden flex text flex-col'>
      <Map resLatLng={resLatLng} setResLatLng={setResLatLng} />
      <form
        className='absolute bottom-0 w-full flex flex-col justify-end gap-3 px-4 rounded-tr-[35px] rounded-tl-[35px] z-[401] py-4 bg-white animateItems'
        onSubmit={handleSubmit}
      >
        {/* Restaurants */}
        <div className='flex flex-col gap-4 h-[180px] overflow-y-auto'>
          {restaurants.map((res, i) => (
            <div
              className={`p-2 rounded-md bg-gray-100 flex flex-col gap-2 cursor-pointer border-2 ${
                value === i ? 'border-main-900' : 'border-transparent'
              }`}
              onClick={() => handleResClick(res.coords, i)}
              key={res.id}
            >
              <div className='flex items-center justify-between'>
                <h4
                  className={`text-lg font-semibold ${
                    res.open ? 'text-gray-800' : 'text-gray-400'
                  }`}
                >
                  {res.name}
                </h4>
                <p
                  className={`text-xs font-semibold ${
                    res.open ? 'text-main-900' : 'text-gray-600'
                  }`}
                >
                  {res.distance.toFixed(2)} كم
                </p>
              </div>
              <div className='flex items-center justify-between'>
                <div className='flex items-center gap-2'>
                  <AiOutlineClockCircle
                    className={`text-lg ${
                      res.open ? 'text-green-500' : 'text-red-500'
                    }`}
                  />
                  <p className='text-sm text-gray-500'>
                    {res.open ? 'فتح' : 'مغلق'}
                  </p>
                </div>
                {res.open24h ? (
                  <p className='text-main-900 text-xs '>مفتوح 24 ساعة</p>
                ) : null}
              </div>
              <p className='text-sm text-gray-700'>{res.addrress}</p>
            </div>
          ))}
        </div>
        <button className='bg-main-900 text-white py-2 w-full rounded-full'>
          استلام من هذا الفرع
        </button>
      </form>
    </div>
  )
}

export default ReciveFromStore
