import { useEffect } from 'react'
import L from 'leaflet'
import { TfiTarget } from 'react-icons/tfi'
import { useMap } from 'react-leaflet'

const LeafletGeocoder = ({
  userLatLng,
  setUserLatLng,
  resLatLng,
  setResLatLng,
}) => {
  const map = useMap()

  const goToLocation = () => {
    if (userLatLng.lat !== 0) {
      map.setView(userLatLng, 8, { animation: true })
    } else {
      if (navigator?.geolocation) {
        navigator.geolocation.getCurrentPosition((location) => {
          if (location) {
            const { latitude, longitude } = location.coords
            setUserLatLng({ lat: latitude, lng: longitude })
          } else {
            alert('قم بالسماح بتحديد الموقع لتحديد موقعك آلياً')
          }
        })
      }
    }
  }

  // const goToRes = () => {
  //   if (resLatLng.lat !== 0) {
  //   }
  // }
  useEffect(() => {
    L.Control.geocoder({
      defaultMarkGeocode: false,
    })
      .on('markgeocode', function (e) {
        var latlng = e.geocode.center
        L.marker(latlng).addTo(map).bindPopup(e.geocode.name).openPopup()
        map.fitBounds(e.geocode.bbox)
      })
      .addTo(map)
  }, [map])

  useEffect(() => {
    if (resLatLng) {
      if (resLatLng.lat !== 0) {
        map.setView(resLatLng, 8, { animation: true })
      }
    }
  }, [map, resLatLng])
  return (
    <button className='target' onClick={goToLocation}>
      <TfiTarget className='w-5 h-5 text-gray-600' />
    </button>
  )
}

export default LeafletGeocoder
