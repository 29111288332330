import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import Item from './Item'
import { data as categories } from '../data'
// import { useEffect, useRef, useState } from 'react'
import 'swiper/css'
import 'swiper/css/pagination'

const Items = (props) => {
  const {
    pagination,
    close,
    isSubmitted,
    filteredItems,
    setSwiper,
    setModalOn,
    modalOn,
    singleItem,
    setSingleItem,
  } = props

  // const swiperRef = useRef(null)
  // const [currentIndex, setCurrentIndex] = useState(
  //   parseInt(localStorage.getItem('current-slider-index'))
  // )

  // useEffect(() => {
  //   localStorage.setItem(
  //     'current-slider-index',
  //     swiperRef?.current?.swiper?.activeIndex
  //   )
  //   swiperRef?.current?.swiper?.slideTo(currentIndex)
  // }, [swiperRef?.current?.swiper?.activeIndex])
  // console.log(currentIndex)

  return (
    <>
      <Swiper
        loop={true}
        autoHeight={true}
        pagination={pagination}
        speed={500}
        modules={[Pagination]}
        onSwiper={(swiper) => setSwiper(swiper)}
        className='mb-10'
        // ref={swiperRef}
        onSlideChange={() => {
          // if (swiperRef?.current?.swiper?.activeIndex === 1) return
          // else setCurrentIndex(swiperRef?.current?.swiper?.activeIndex)
          const activeCat = document.querySelector(
            '.swiper-pagination-bullet-active'
          )
          document.documentElement.scrollTop = 0
          activeCat?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          })
          close()
        }}
      >
        {categories.map((category, i) => (
          <SwiperSlide key={i}>
            <div className='relative space-y-2 flex flex-col justify-center overflow-hidden items-center animateItems'>
              <div className='w-full p-4 flex flex-col gap-2 items-center bg-white dark:bg-gray-700'>
                {isSubmitted ? (
                  filteredItems.length === 0 ? (
                    <div className='w-full flex items-center font-semibold gap-4 justify-center flex-col my-5 dark:text-white'>
                      <img
                        src='../../img/delete.png'
                        alt='not-found'
                        width='100em'
                        height='100em'
                      />
                      <h2 className='text-3xl text-center text-[#d59f29] dark:text-white'>
                        لا يوجد نتائج
                      </h2>
                    </div>
                  ) : (
                    filteredItems.map((item, index) => (
                      <Item
                        item={item}
                        key={index}
                        modalOn={modalOn}
                        setModalOn={setModalOn}
                        singleItem={singleItem}
                        setSingleItem={setSingleItem}
                      />
                    ))
                  )
                ) : (
                  category.items?.map((item, index) => (
                    <Item
                      item={item}
                      key={index}
                      modalOn={modalOn}
                      setModalOn={setModalOn}
                      singleItem={singleItem}
                      setSingleItem={setSingleItem}
                    />
                  ))
                )}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}

export default Items
