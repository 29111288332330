import { useGlobalContext } from '../context'
// import { Link } from 'react-router-dom'
import CartItem from '../components/CartItem'
import { IoArrowBackCircle } from 'react-icons/io5'
import logo_light from '../img/logo.png'
import logo_dark from '../img/logo2.png'
import { IoIosArrowBack } from 'react-icons/io'


const CartPage = ({
  quantity,
  setQuantity,
  setModalOn,
  setItemOn,
  setSingleItem,
}) => {


  const { cartData } = useGlobalContext()
  const deliveryFee = 5 // سعر رسوم التوصيل
  const total = cartData
    .reduce((acc, item) => acc + item.price, 0)
    .toLocaleString('en-US')
  const date = new Date(Date.now())
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let ampm = hours >= 12 ? 'مساءً' : 'صباحاً'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes
  const time = `${hours}:${minutes} ${ampm}`
  const orderTotal = (
    deliveryFee + cartData.reduce((acc, item) => acc + item.price, 0)
  ).toLocaleString('en-US')
  
  const message = `%0A%2A نوع الطلب %2A%3A توصيل سفري %0A---------------------------%0A${cartData
    .map((item) => {
  
      let size = ''
      let extras = ''
      let edits = ''
      if (item.size) {
        switch (item.size.name) {
          case 'small':
            size = 'صغير'
            break
          case 'medium':
            size = 'وسط'
            break
          case 'large':
            size = 'كبير'
            break
          default:
            size = ''
        }
      }
      if (item.extra && item.extra.length > 0) {
        extras = `%2A الإضافات %2A%3A %0A${item.extra
          .map((e) => `%20 %20 • ${e.name}`)
          .join('%0A')} %0A`
      }
      if (item.edits && item.edits.length > 0) {
        edits = `%2A التعديلات %2A%3A %0A${item.edits
          .map((e) => `%20 %20 • ${e}`)
          .join('%0A')}`
      }
      return `%2A الصنف %2A%3A ${item.name} %0A${
        size ? `%2A الحجم %2A%3A ${size} %0A` : ''
      }%2A الكمية %2A%3A ${item.quantity} %0A%2A السعر %2A%3A ${
        item.price
      } ريال %0A${extras ? extras : ''}${edits ? edits : ''}`
    })
    .join(
      '%0A---------------------------%0A'
    )}%0A---------------------------%0A%0A%2A مبلغ الطلب %2A%3A ${total} ريال %0A%2A رسوم التوصيل %2A%3A ${deliveryFee} ريال %0A%2A المجموع %2A%3A ${orderTotal} ريال %0A%2A وقت الطلب %2A%3A ${time}`

  return (
    <div
      dir='rtl'
      className='fixed overflow-y-auto inset-x-0 max-w-md md:ml-auto md:mr-0 mx-auto h-full bg-white dark:bg-gray-700 overflow-x-hidden w-full z-[401] fastAnimate'
    >
      <div className='z-50 fixed w-full bg-white max-w-md mx-auto h-16 top-auto shadow-[1px_1px_8px_#d59f2966] py-1 flex items-center justify-between gap-2 dark:bg-gray-700'>
        <div className='col-span-9 grid justify-start items-center'>
          <div
            onClick={() => setModalOn(false)}
            className='col-span-10 pr-4 text-md font-semibold text-gray-500 dark:text-white overflow-y-hidden flex items-center cursor-pointer gap-2'
          >
            <img
              src={logo_light}
              alt='main logo'
              className=' w-[56px] h-[57px] dark:hidden'
            />
            <img
              src={logo_dark}
              alt='main logo'
              className=' w-[56px] h-[57px] hidden dark:block'
            />
            <h1 className='font-extrabold flex gap-1 flex-col text-md text-primaryGreen-500 dark:text-primaryGreen-200'>
              <span className='inline-block transform translate-y-1 mx-0.5 text-brown-400'>
                مطعم نوى الشام
              </span>
              <span className='font-extrabold text-md text-primaryGreen-500 dark:text-primaryGreen-200 overflow-hidden'>
                {' '}
                Nawa Al-Sham
              </span>
            </h1>
          </div>
        </div>
        <IoIosArrowBack
          className='w-10 h-10 ml-4 rounded-full text-[#d59f29] hover:bg-main-900/80 hover:text-white dark:text-white transition p-2 cursor-pointer'
          title='رجوع'
          onClick={() => setModalOn(false)}
        />
      </div>
      <div className='w-full pt-20 px-4 flex flex-col gap-4 items-center justify-end bg-white dark:bg-gray-700'>
        {cartData.length > 0 ? (
          cartData.map((item, i) => (
            <CartItem
              key={i}
              item={item}
              quantity={quantity}
              setQuantity={setQuantity}
              setModalOn={setModalOn}
              setItemOn={setItemOn}
              setSingleItem={setItemOn}
            />
          ))
        ) : (
          <div className='w-full flex items-center font-semibold gap-4 justify-center flex-col my-20 dark:text-white'>
            <img
              src='../../img/add-to-basket.png'
              alt='add to basket icon'
              width='100em'
              height='100em'
            />
            <h2 className='text-3xl text-center text-[#d59f29] dark:text-white'>
              السلة فارغة
            </h2>
            <button
              onClick={() => setModalOn(false)}
              className='w-full font-semibold flex items-center justify-center text-[#d59f29] gap-2 border-2 border-[#d59f29] rounded-full py-2 px-4 w-full dark:bg-gray-900 dark:text-white dark:border-[#111827]'
            >
              <IoArrowBackCircle className='text-2xl icon-flip' /> رجوع
            </button>
          </div>
        )}
      </div>
      {cartData.length > 0 ? (
        <div className='flex flex-col gap-4 my-6 justify-start px-4'>
          <div className='flex items-center justify-between foa9l'>
            <p className='border-[#d59f29] text-[#d59f29] dark:text-white text-lg font-semibold'>
              المجموع
            </p>
            <div className='flex items-center font-semibold gap-2 dark:text-white'>
              <span className='text-[#d59f29] dark:text-white text-lg font-semibold'>
                {cartData
                  .reduce((acc, item) => acc + item.price, 0)
                  .toLocaleString('en-US')}
              </span>
              ريال
            </div>
          </div>
          <div className='font-semibold flex flex-col gap-4'>
            <a
              href={`https://api.whatsapp.com/send?phone=+966534408822&text=${message}`}
              target='_blank'
              rel='noreferrer'
              className='py-2 w-full rounded-full bg-[#d59f29] text-white text-center'
            >
              إرسال الطلب للواتساب
            </a>
            <button
              onClick={() => setModalOn(false)}
              className='w-full font-semibold flex items-center justify-center text-[#d59f29] gap-2 border-2 border-[#d59f29] rounded-full py-2 px-4 w-full dark:bg-gray-900 dark:text-white dark:border-[#111827]'
            >
              <IoArrowBackCircle className='text-2xl icon-flip' /> رجوع
            </button>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default CartPage
