import { useState } from 'react'
import Modal from './Modal'
import { ToastContainer, toast } from 'react-toastify'
import logo from '../img/logo.png'
import 'react-toastify/dist/ReactToastify.css'

const TableNumber = () => {
  const [tableOn, setTableOn] = useState(false)
  const [value, setValue] = useState(1)
  const confirmOrder = () => {
    setTableOn(false)
    toast.success('تم تأكيد الطلب بنجاح')
  }
  return (
    <div className='h-[calc(100vh-136px)] w-full'>
      <div className='flex items-center justify-center flex-wrap gap-4'>
        {Array.from({ length: 9 }, (_, i) => i + 1).map((table) => (
          <div
            className='flex items-center justify-center w-28 h-28 bg-red-500 rounded-lg bg-logo bg-norepeat text-white shadow-md text-2xl overflow-hidden'
            style={{ background: `url(${logo})`, backgroundSize: 'cover' }}
            key={table}
            onClick={() => {
              setValue(table)
              setTableOn(true)
            }}
          >
            <button className='w-full h-full bg-black/50'>{table}</button>
          </div>
        ))}
      </div>
      {tableOn ? (
        <Modal
          setModalOn={setTableOn}
          title={`طاولة رقم ${value}`}
          className='shadow-md'
        >
          <div className='flex items-center gap-4 mt-4'>
            <button
              className='py-2 w-full rounded-lg bg-main-900 text-white'
              onClick={confirmOrder}
            >
              تأكيد الطلب
            </button>
            <button
              className='py-2 w-full rounded-lg bg-red-400 text-white'
              onClick={() => setTableOn(false)}
            >
              إلغاء
            </button>
          </div>
        </Modal>
      ) : null}
      <ToastContainer
        closeButton={true}
        closeOnClick={true}
        position='bottom-center'
        rtl={true}
      />
    </div>
  )
}

export default TableNumber
